import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useLogout } from "../firebase files/hooks/useLogout";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDocument } from "../firebase files/hooks/useDocument";
import { useFirestore } from "../firebase files/hooks/useFirestore";
import DarkModeToggle from "react-dark-mode-toggle";
import { useRef } from "react";
import { projectStorage } from "../firebase config/config";
import SidebarNew from "./SidebarNew";
import useCart from "../firebase files/hooks/useCart";
import { projectFirestore } from "../firebase config/config";
// styles & images
import "./Navbar.css";
// import Temple from "../assets/img/logo.png";
export default function Navbar() {
    // Scroll to the top of the page whenever the location changes start
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const projectId = projectFirestore._databaseId.projectId;

    // use cart hook


    const showCart = () => {
        navigate("/cart")
    }

    const { cartItems } = useCart()

    const { document: appTypeDetails, error: appTypeDetailserror } = useDocument(
        "settings",
        "AppType"
    );

    const { document: NavMenuNames, error: NavMenuNameserror } = useDocument(
        "settings",
        "EditNavbarMenus"
    );

    const { document: iconTypeDocument, error: iconTypeDocumentError } =
        useDocument("settings", "Icons");

    const { document: functionTypeDetails, error: functionTypeDetailserror } = useDocument("settings", "FunctionType")


    const { document: navTypeDoc, error: navTypeDocerror } = useDocument(
        "settings",
        "navbarType"
    );

    const { document: pageControlDoc, error: pageControlDocError } = useDocument(
        "settings",
        "pagesControl"
    );

    const { document: dbTemplatesPaletteDocuments, error: dbTemplatesPaletteError } =
        useDocument("settings", "themePalette");

    const { updateDocument, deleteDocument } = useFirestore("settings");

    const { document, error } = useDocument("settings", "organisation");


    // START CODE FOR LIGHT/DARK MODE
    const toggleUserDarkMode = async () => {
        // Retrieving a value from localStorage
        const currentModeStatus = localStorage.getItem("mode");

        const newDisplayMode = currentModeStatus === "light" ? "dark" : "light";
        // Storing a value in localStorage
        localStorage.setItem("mode", newDisplayMode);

        window.dispatchEvent(new Event("storage"));
    };
    // END CODE FOR LIGHT/DARK MODEs

    const { user } = useAuthContext();
    const navigate = useNavigate();
    // for show active tab with diff color
    const [activeMenu, setActiveMenu] = useState("dashboard");
    const [activeSubMenu, setActiveSubMenu] = useState("");

    // CHANGE LOGO CODE START
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(false); // State to track if the Confirm button should be disabled

    const handlePlusIconClick = () => {
        fileInputRef.current.click(); // Programmatically click the file input
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleConfirmButtonClick = async () => {
        if (!selectedFile) return;
        setIsConfirmButtonDisabled(true); // Enable the Confirm button once a file is selected

        const storageRef = projectStorage.ref();
        const logoRef = storageRef.child(`master_data/logo/${selectedFile.name}`);

        try {
            const snapshot = await logoRef.put(selectedFile);
            const shortLogo = await snapshot.ref.getDownloadURL();
            await updateDocument("organisation", { shortLogo: shortLogo });
            setSelectedFile("");
        } catch (error) {
            console.error("Error uploading file:", error);
        } finally {
            setIsConfirmButtonDisabled(false);
        }
    };
    const handleCancelButtonClick = () => {
        setSelectedFile("");
    };
    // CHANGE LOGO CODE END

    const [showProfileMenu, setShowProfileMenu] = useState(false);

    const profileRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (profileRef.current) {
                profileRef.current.classList.add("vibrate");
                setTimeout(() => {
                    profileRef.current.classList.remove("vibrate");
                }, 300);
            }
        }, 10000);

        return () => clearInterval(interval);
    }, []);


    const handleScheduleBtnClick = () => {
        if (user) {
            navigate('/products')
        } else {
            navigate('/login')
        }
    }
    return (
        <>
            {navTypeDoc && navTypeDoc.activeNav == "FixNav" ? (
                <>
                    <nav className="navbar-default-parent sticky-top"
                        style={{
                            background: dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "diwaliThemePalette"
                                ? "linear-gradient(to right, #F57000, #F5BF00)"
                                : dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "defaultThemePalette"
                                    ? "var(--page-bg)"
                                    : dbTemplatesPaletteDocuments && dbTemplatesPaletteDocuments.activeThemePalette === "holiThemePalette"
                                        ? `radial-gradient(circle at 10% 20%, rgba(255, 0, 0, 1), transparent 20%),
                 radial-gradient(circle at 80% 30%, rgba(0, 255, 0, 1), transparent 20%),
                 radial-gradient(circle at 40% 70%, rgba(0, 0, 255, 1), transparent 20%),
                 radial-gradient(circle at 90% 80%, rgba(255, 255, 0, 1), transparent 20%),
                 radial-gradient(circle at 30% 90%, rgba(255, 165, 0, 1), transparent 20%),
                 radial-gradient(circle at 20% 50%, rgba(255, 0, 255, 1), transparent 15%),
                 radial-gradient(circle at 70% 40%, rgba(0, 255, 255, 1), transparent 25%),
                 radial-gradient(circle at 50% 50%, rgba(255, 192, 203, 1), transparent 30%),
                 radial-gradient(circle at 60% 60%, rgba(173, 216, 230, 1), transparent 25%),
                 radial-gradient(circle at 85% 20%, rgba(255, 223, 186, 1), transparent 20%)`
                                        : null
                        }}
                    >
                        <div className="container-fluid">
                            <ul>
                                <li
                                    className={`col-6 col-md-3 col-lg-3  ${user && user.role === "admin"
                                        ? "navbar-logo-only-admin"
                                        : "navbar-logo-only-customer"
                                        }`}
                                    onClick={
                                        user && user.role === "admin" ? handlePlusIconClick : null
                                    }
                                >
                                    <div className="navbar-logo-container d-flex align-items-center">
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: "none" }}
                                            onChange={handleFileInputChange}
                                        />
                                        <img
                                            src={
                                                selectedFile
                                                    ? URL.createObjectURL(selectedFile)
                                                    : document && document.shortLogo
                                            }
                                            alt="logo"
                                            className={
                                                document && document.full_name === ""
                                                    ? "navbar-logo-only-without-title"
                                                    : "navbar-logo-only-with-title"
                                            }
                                        />
                                        <p className="nav_logo_text">
                                            {document && document.full_name}
                                        </p>
                                    </div>
                                </li>
                                {selectedFile && (
                                    <>
                                        <button
                                            className="btn_fill"
                                            onClick={handleConfirmButtonClick}
                                            disabled={isConfirmButtonDisabled}
                                        >
                                            {isConfirmButtonDisabled ? "Changing..." : "confirm"}
                                        </button>
                                        &nbsp;
                                        <button
                                            className="btn_border"
                                            onClick={handleCancelButtonClick}
                                        >
                                            cancel
                                        </button>
                                    </>
                                )}

                                <div className=""></div>
                                <div className="default-navbar-large">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div></div>
                                        <div className="navbar-laptop-menu-links-div">
                                            <div className="navbar-laptop-menu-links">
                                                {/* START SHOW NEWLY CREATED ARRAY MAP MENUS HERE */}
                                                {NavMenuNames && NavMenuNames.menuDetails && Array.isArray(NavMenuNames.menuDetails) && NavMenuNames.menuDetails.length > 0 ? (
                                                    NavMenuNames.menuDetails.map((menuItem, index) => (
                                                        <>
                                                            <Link to={menuItem.pageUrl} key={index}>
                                                                <div
                                                                    className={`navbar-menu-item ${activeMenu === menuItem.menuName ? 'active_menu' : ''}`}
                                                                    onClick={() => setActiveMenu(menuItem.menuName)}
                                                                    // onClick={() =>
                                                                    //     setActiveMenu(activeMenu === menuItem.menuName ? "" : menuItem.menuName)
                                                                    // }
                                                                    onMouseEnter={() => setActiveSubMenu(menuItem.menuName)}
                                                                    onMouseLeave={() => setActiveSubMenu("")}
                                                                >
                                                                    <span className="material-symbols-outlined">
                                                                        {menuItem.iconName}
                                                                    </span>
                                                                    <h1>{menuItem.menuName}</h1>

                                                                    {/* Render Submenu */}
                                                                    {activeSubMenu === menuItem.menuName && menuItem.subMenu && menuItem.subMenu.length > 0 && (
                                                                        <div className="navbar-sub-menu-dropdown-menu">
                                                                            {menuItem.subMenu.map((subMenuItem, subIndex) => (
                                                                                <p key={subIndex}>
                                                                                    <Link to={subMenuItem.subMenuPageUrl} className="d-flex align-items-center justify-content-between navbar-sub-menu-dropdown-name">
                                                                                        <div className="d-flex align-items-center">
                                                                                            <span className="material-symbols-outlined">
                                                                                                {subMenuItem.subMenuIcon}
                                                                                            </span>
                                                                                            {subMenuItem.subMenuName}
                                                                                        </div>
                                                                                    </Link>
                                                                                </p>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Link>
                                                        </>
                                                    ))
                                                ) : (
                                                    <p>No menu items available.</p>  // Fallback message if no menu items exist
                                                )}
                                                {/* END SHOW NEWLY CREATED ARRAY MAP MENUS HERE */}


                                                {/* SHOPING CART START */}
                                                {pageControlDoc && pageControlDoc.cart === true && pageControlDoc && pageControlDoc.login === true &&
                                                    <div
                                                        onClick={showCart}
                                                        className={`${location.pathname === "/cart"
                                                            ? ""
                                                            : ""
                                                            } `}
                                                    >
                                                        <span className="material-symbols-outlined">
                                                            shopping_cart
                                                        </span>
                                                        {
                                                            user && user
                                                                ?
                                                                (
                                                                    <span className="navbar-cart-quantity">
                                                                        <span className="cart-quantity-counter">{cartItems.length}</span>
                                                                    </span>
                                                                )
                                                                :
                                                                ""
                                                        }

                                                    </div>
                                                }
                                                {/* SHOPING CART END */}


                                                <DarkModeToggle
                                                    onChange={() => toggleUserDarkMode()}
                                                    checked={localStorage.getItem("mode") === "dark"}
                                                    size={40}
                                                />

                                                {iconTypeDocument &&
                                                    iconTypeDocument.BottomRightFixedIcons.notification == true && (
                                                        <Link to="/notifications" className="ab_whatsapp_icon">
                                                            <img src="/assets/img/icons8-notification.gif" />
                                                        </Link>
                                                    )}
                                            </div>

                                            {user ? (
                                                <div
                                                    className="profile-menu-container"
                                                    onMouseEnter={() => setShowProfileMenu(true)}
                                                    onMouseLeave={() => setShowProfileMenu(false)}
                                                >
                                                    <Link
                                                        to="/profile"
                                                        className={`menu_single profile pointer ${location.pathname === "/profile" ? "active" : ""}`}
                                                        style={{ display: "flex" }}
                                                        ref={profileRef}
                                                    >
                                                        <span>Hi, {user.fullName.split(' ')[0]}</span>
                                                        <div className="user_img">
                                                            {user.photoURL === "" ? (
                                                                <img
                                                                    src="https://firebasestorage.googleapis.com/v0/b/propdial-dev-aa266.appspot.com/o/userThumbnails%2F1default.png?alt=media&token=38880453-e642-4fb7-950b-36d81d501fe2&_gl=1*1bbo31y*_ga*MTEyODU2MDU1MS4xNjc3ODEwNzQy*_ga_CW55HF8NVT*MTY4NjIzODcwMC42OS4xLjE2ODYyMzkwMjIuMC4wLjA."
                                                                    alt=""
                                                                    className="nav_profile_icon"
                                                                />
                                                            ) : (
                                                                <img src={user.photoURL} alt="" />
                                                            )}
                                                        </div>
                                                    </Link>

                                                    {showProfileMenu && functionTypeDetails && functionTypeDetails.function === "ecommerce" && (
                                                        <div className="profile-dropdown-menu">
                                                            <Link to="/profile" className="navbar-booking-dropdown-item">
                                                                <span className="material-symbols-outlined">person</span>
                                                                <span>My Profile</span>
                                                            </Link>
                                                            <Link to="/wishlist" className="navbar-booking-dropdown-item">
                                                                <span className="material-symbols-outlined">bookmark_heart</span>
                                                                <span>Wishlist</span>
                                                            </Link>
                                                            <Link to="/orders" className="navbar-booking-dropdown-item">
                                                                <span className="material-symbols-outlined">shopping_bag</span>
                                                                <span>My Orders</span>
                                                            </Link>
                                                            <Link to="/address" className="navbar-booking-dropdown-item">
                                                                <span className="material-symbols-outlined">location_on</span>
                                                                <span>My Addresses</span>
                                                            </Link>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                pageControlDoc && pageControlDoc.login &&
                                                <Link to="/profile">
                                                    <div
                                                        className={`menu_single login pointer ${location.pathname === "/login" ? "active" : ""
                                                            }`}
                                                    >
                                                        <span className="material-symbols-outlined">
                                                            account_circle
                                                        </span>
                                                    </div>
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* PHONE NAV START */}
                                <div className="default-navbar-small">
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div></div>
                                        {(appTypeDetails &&
                                            appTypeDetails.apptype === "liteplus") ||
                                            (appTypeDetails && appTypeDetails.apptype === "pro" && (
                                                <div className="d-flex">
                                                    {
                                                        projectId == "smart-wash-80a57"
                                                            ?
                                                            (
                                                                <>
                                                                    <button className="schedule-pickup-nav-button" onClick={handleScheduleBtnClick}>
                                                                        <span className="material-symbols-outlined">dry_cleaning</span>
                                                                        Schedule a Pickup
                                                                    </button>
                                                                </>
                                                            )
                                                            :
                                                            <>
                                                                <Link to="/notifications">
                                                                    <li>
                                                                        <div className="navbar-notification-div">
                                                                            <span className="material-symbols-outlined">
                                                                                notifications
                                                                            </span>
                                                                            <div></div>
                                                                        </div>
                                                                    </li>
                                                                </Link>
                                                            </>
                                                    }

                                                    {
                                                        user && functionTypeDetails && functionTypeDetails.function === "ecommerce" &&
                                                        (
                                                            <div
                                                                className="profile-menu-container"
                                                                onMouseEnter={() => setShowProfileMenu(true)}
                                                                onMouseLeave={() => setShowProfileMenu(false)}
                                                            >
                                                                <Link>
                                                                    <li>
                                                                        <div className="navbar-hover-menu">
                                                                            <span className="material-symbols-outlined">
                                                                                more_vert
                                                                            </span>
                                                                            <div></div>
                                                                        </div>
                                                                    </li>

                                                                </Link>

                                                                {showProfileMenu && functionTypeDetails && functionTypeDetails.function === "ecommerce" && (
                                                                    <div className="profile-dropdown-menu">
                                                                        <Link to="/cart" className="navbar-booking-dropdown-item" onClick={() => setShowProfileMenu(false)}>
                                                                            <span className="material-symbols-outlined">shopping_cart</span>
                                                                            <span>Cart</span>
                                                                        </Link>
                                                                        <Link to="/wishlist" className="navbar-booking-dropdown-item" onClick={() => setShowProfileMenu(false)}>
                                                                            <span className="material-symbols-outlined">bookmark_heart</span>
                                                                            <span>Wishlist</span>
                                                                        </Link>
                                                                        <Link to="/orders" className="navbar-booking-dropdown-item" onClick={() => setShowProfileMenu(false)}>
                                                                            <span className="material-symbols-outlined">shopping_bag</span>
                                                                            <span>My Orders</span>
                                                                        </Link>
                                                                        <Link to="/address" className="navbar-booking-dropdown-item" onClick={() => setShowProfileMenu(false)}>
                                                                            <span className="material-symbols-outlined">location_on</span>
                                                                            <span>My Addresses</span>
                                                                        </Link>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                {/* PHONE NAV END */}
                            </ul>
                        </div>
                    </nav >
                </>
            ) : (
                <SidebarNew
                    navigate={navigate}
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                />
            )
            }
        </>
    );
}